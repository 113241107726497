var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-account-cash", title: "Folha de Pagamento" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.isAnima()
                ? _c("s-icon-report-download-optimized", {
                    attrs: {
                      icon: "mdi-file-delimited-outline",
                      "tool-tip": "Relatório Consolidado (Status Gerado)",
                      "show-filter-payment-export": "",
                      report: "66",
                      "show-message": ""
                    }
                  })
                : _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showDowload = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-file-excel")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Relatório ")])]
                  ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Novo registro ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", {
        attrs: {
          color: _vm.colorProgress,
          text: _vm.textProgress,
          show: _vm.loadingProgress
        }
      }),
      _c("sys-print-payment-export", {
        attrs: { show: _vm.showDowload },
        on: {
          "update:show": function($event) {
            _vm.showDowload = $event
          }
        }
      }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          "show-append": ""
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      loading: _vm.loadingRefresh,
                                      color: "success",
                                      icon: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.getData(true)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { btn: "" } }, [
                                  _vm._v("mdi-refresh-circle")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v(" Atualizar ")])]
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.date",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(_vm._f("dateTime")(item.updated_at)) + " "),
                _c("br"),
                _c("small", [_vm._v(_vm._s(item.updated_at_human))])
              ]
            }
          },
          {
            key: "item.type",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.type.name) + " "),
                item.complementary
                  ? _c("emc-info-help", {
                      attrs: {
                        iconColor: "success",
                        icon: "mdi-cash-plus",
                        title: "Informações",
                        text: "Folha Complementar"
                      }
                    })
                  : _vm._e(),
                item.description
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "small",
                                    _vm._g(
                                      _vm._b({}, "small", attrs, false),
                                      on
                                    ),
                                    [
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          item.description.length > 80
                                            ? item.description.substring(
                                                0,
                                                50
                                              ) + " ..."
                                            : item.description
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.description) + " ")
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.status.name) + " "),
                item.status.id == 2
                  ? _c(
                      "span",
                      [
                        _c("v-progress-linear", {
                          attrs: {
                            color: "deep-purple accent-4",
                            indeterminate: "",
                            rounded: "",
                            height: "3"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                !_vm.isAnima() &&
                item.payment_export_status_id != 1 &&
                item.payment_export_status_id != 2
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            small: "",
                                            color: "success"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.download(
                                                32,
                                                "options[payment_export_id]=" +
                                                  item.id
                                              )
                                            }
                                          }
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-download")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(" Dowload ")])]
                    )
                  : _vm._e(),
                _vm.isAnima() &&
                item.payment_export_status_id != 1 &&
                item.payment_export_status_id != 2
                  ? _c("s-icon-report-download-optimized", {
                      attrs: {
                        "auto-small": "",
                        "tool-tip": "Relatório em Excel",
                        report: "32",
                        params: _vm.getParams(item),
                        "show-message": ""
                      }
                    })
                  : _vm._e(),
                _vm.isAnima() &&
                item.payment_export_status_id != 1 &&
                item.payment_export_status_id != 2
                  ? _c("s-icon-report-download-optimized", {
                      attrs: {
                        "auto-small": "",
                        "tool-tip": "Layout TXT - Totvs",
                        icon: "mdi-file-delimited-outline",
                        color: "grey",
                        report: "66",
                        params: _vm.getParams(item),
                        "show-message": ""
                      }
                    })
                  : _vm._e(),
                item.payment_export_status_id != 2 && item.has_errors
                  ? _c("s-icon-report-download-optimized", {
                      attrs: {
                        "auto-small": "",
                        "tool-tip": "Erros de Validação",
                        color: "error",
                        report: "82",
                        params: _vm.getParams(item),
                        "show-message": ""
                      }
                    })
                  : _vm._e(),
                item.payment_export_status_id == 3
                  ? _c("emc-table-icon", {
                      staticClass: "mr-2",
                      attrs: {
                        "tool-tip": "Editar Items",
                        icon: "mdi-file-document-edit-outline",
                        color: "purple"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showItemsExport(item)
                        }
                      }
                    })
                  : _vm._e(),
                item.payment_export_status_id <= 3
                  ? _c("emc-table-icon-edit", {
                      attrs: {
                        "tool-tip": item.lock_edit_work_load
                          ? "Desbloquear Edição de Carga Horária"
                          : "Bloquear Edição de Carga Horária",
                        color: item.lock_edit_work_load ? "error" : "success",
                        icon: item.lock_edit_work_load
                          ? "mdi-lock-outline"
                          : "mdi-lock-open-variant-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveLock(item)
                        }
                      }
                    })
                  : _vm._e(),
                item.payment_export_status_id != 2 &&
                item.payment_export_status_id != 4 &&
                item.payment_export_status_id != 5
                  ? _c("emc-table-icon", {
                      staticClass: "mr-2",
                      attrs: {
                        color: "amber",
                        icon:
                          item.payment_export_status_id == 1
                            ? "mdi-flash"
                            : "mdi-reload",
                        "tool-tip":
                          item.payment_export_status_id == 1
                            ? "Gerar Folha"
                            : "Regerar Folha"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showGenerateItem(item)
                        }
                      }
                    })
                  : _vm._e(),
                item.payment_export_status_id != 1 &&
                item.payment_export_status_id != 2 &&
                item.payment_export_status_id != 4 &&
                item.payment_export_status_id != 5
                  ? _c("emc-table-icon", {
                      staticClass: "mr-2",
                      attrs: {
                        color: "error",
                        icon: "mdi-cancel",
                        "tool-tip": "Cancelar"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showProcessDialog(item, 4)
                        }
                      }
                    })
                  : _vm._e(),
                item.payment_export_status_id == 3
                  ? _c("emc-table-icon", {
                      staticClass: "mr-2",
                      attrs: {
                        color: "success",
                        icon: "mdi-check-bold",
                        "tool-tip": "Pagamento processado"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showProcessDialog(item, 5)
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("sys-payment-export-register", {
        attrs: { model: _vm.model, show: _vm.showForm },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("sys-payment-export-list-item", {
        attrs: { model: _vm.modelItems, show: _vm.showItems },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showItems = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Essa ação irá excluir e regerar todos os registros de pagamento. Deseja Regerar?",
          show: _vm.showMessageGenerate
        },
        on: {
          "update:show": function($event) {
            _vm.showMessageGenerate = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "amber" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.generate(_vm.selectedItem)
                      }
                    }
                  },
                  [
                    _vm._v("Regerar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-reload")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Utilize essa opção caso o pagamento já tenha sido processado. Não será possível editar esse registro novamente",
          disabled: _vm.loading,
          show: _vm.showProcessPayment
        },
        on: {
          "update:show": function($event) {
            _vm.showProcessPayment = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "success",
                      loading: _vm.loading,
                      disabled: _vm.loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.save(_vm.itemProcessPayment, 4)
                      }
                    }
                  },
                  [
                    _vm._v("Processar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-check-bold")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Deseja cancelar o processamento? Essa ação irá liberar para alterações de carga horária docente.",
          disabled: _vm.loading,
          show: _vm.showCancelPayment
        },
        on: {
          "update:show": function($event) {
            _vm.showCancelPayment = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.loading,
                      disabled: _vm.loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.save(_vm.itemProcessPayment, 5)
                      }
                    }
                  },
                  [
                    _vm._v("Cancelar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-cancel")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }